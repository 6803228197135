<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar
          :title="courses.title"
          left-arrow
          @click-left="goBack"
        >
        <template #right>
          <div @click="toHome()">
            <van-icon name="wap-home-o" size="18" />
            <div style="float: right; margin-left: 5px; font-size: 15px">
              首页
            </div>
          </div>
        </template>
      </van-nav-bar>
      </van-sticky>
      <div class="detail">
        <van-row>
          <van-col span="24">
            <van-col span="3">
              <van-image
                style="float: left"
                round
                width="41px"
                height="41px"
                fit="fill"
                :src="courses.headImgurl"
                 @click.stop="toUserHomePage(courses.creatorId)"
              />
            </van-col>

            <van-col span="11">
              <div
                style="
                  float: left;
                  color: #069;
                  font-size: 16px;
                  font-weight: bold;
                  width: 100%;
                "
              >
                {{ courses.trueName }}
                <userIcon :iconstring="courses.icon" ></userIcon>
                <a style="color: #069; font-size: 15px; font-weight: bold">
                  {{ courses.departName }} 
                </a>
              </div>

              <div style="float: left; color: #696969">
                {{ courses.creationTime | relTime }}
              </div>
              <div style="float: left; color: #696969; margin-left: 13px">
                浏览{{ courses.readCount }}人次
              </div>
            </van-col>
            <van-col span="10"> </van-col>
          </van-col>
        </van-row>
        <van-tabs
          v-model="activeName"
          sticky
          v-if="playerOptions.sources.length > 0 && audioList.length > 0"
        >
          <van-tab
            title="视频"
            name="c"
            v-if="playerOptions.sources.length > 0"
          >
            <van-row>
              <van-col span="24">
                <div class="znvideo">
                  <!-- <video-player
                    class="video-player-box vjs-big-play-centered"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ready="playerReadied"
                    style="object-fit: cover"
                  >
                  </video-player> -->
                  <video style="max-width:100%"
              v-if='courses.videoUrl != undefined && courses.videoUrl != ""'
               controls="" :src="courses.videoUrl"></video>
                </div>
              </van-col>
            </van-row>
          </van-tab>

          <van-tab title="音频" name="b" v-if="audioList.length > 0">
            <van-row>
              <van-col span="24">
                <div class="znaudio">
                  <div class="name">
                    {{ currentAudioName || audioList[0].name }}
                  </div>
                  <audio-player
                    ref="audioPlayer"
                    :showPrevButton="false"
                    :showNextButton="false"
                    :isLoop="false"
                    :showVolumeButton="false"
                    :progress-interval="100"
                    :audio-list="audioList.map((elm) => elm.url)"
                    :before-play="handleBeforePlay"
                    theme-color="rgb(157,29,34)"
                  />
                </div>
              </van-col>
            </van-row>
          </van-tab>
        </van-tabs>

        <van-row
          v-if="playerOptions.sources.length > 0 && audioList.length == 0"
        >
          <van-col span="24">
            <div >
      <video style="max-width:100%"
              v-if='courses.videoUrl != undefined && courses.videoUrl != ""'
               controls="" :src="courses.videoUrl"></video>
            </div>
          </van-col>
        </van-row>
        <van-row
          v-if="playerOptions.sources.length == 0 && audioList.length > 0"
        >
          <van-col span="24">
            <div class="znaudio">
              <div class="name">
                {{ currentAudioName || audioList[0].name }}
              </div>
              <audio-player
                ref="audioPlayer"
                :showPrevButton="false"
                :showNextButton="false"
                :isLoop="false"
                :showVolumeButton="false"
                :progress-interval="100"
                :audio-list="audioList.map((elm) => elm.url)"
                :before-play="handleBeforePlay"
                theme-color="rgb(157,29,34)"
              />
            </div>
          </van-col>
        </van-row>

        <van-divider
          v-if="playerOptions.sources.length > 0 || audioList.length > 0"
        />
        <van-row>
          <van-col span="24">
            <div class="acontent" v-html="courses.detailsHtml"></div>
          </van-col>
        </van-row>

        <van-cell>
          <!--话题-->
          <van-col span="1"></van-col>
          <van-col span="22">
            <div style="margin-top: 10px">
              <div
                style="display: inline-block"
                v-if="
                  courses.typeName != undefined && courses.typeName.length > 0
                "
                @click="toType(courses)"
              >
                <van-tag
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  size="large"
                  >#{{ courses.typeName }}#
                </van-tag>

                <span style="width: 10px; display: inline-block"></span>
              </div>
              <div
                style="display: inline-block"
                v-if="
                  courses.topicName != undefined && courses.topicName.length > 0
                "
                @click="toTopic(courses)"
              >
                <van-tag
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >#{{ courses.topicName }}#
                </van-tag>
                <span style="width: 10px; display: inline-block"></span>
              </div>
              <van-tag
                @click="toProduct(courses)"
                v-if="
                  courses.productName != undefined &&
                  courses.productName.length > 0
                "
                size="large"
                color="rgb(255 242 219)"
                text-color="#9d1d22"
                >{{ courses.productName }}
              </van-tag>
            </div>
          </van-col>
        </van-cell>
      </div>
        <commentScore v-if="courses.systemType==1" :itemId="courses.id" itemType="4" ></commentScore>
      <comment :CommentPage="CommentPage"></comment>
    </div>
  </div>
</template>

<script>
//import { videoPlayer } from "vue-video-player";
//import "video.js/dist/video-js.css";

import AudioPlayer from "@liripeng/vue-audio-player";

import "@liripeng/vue-audio-player/lib/vue-audio-player.css";

//import {Howl, Howler} from 'howler';
import { getBbsInfo } from "@/api/bbs";
import Comment from "../components/comment";
import CommentScore from "../components/commentScore";
import UserIcon from "../components/userIcon";

export default {
  name: "bbsdetail",
  data() {
    return {
      loading: true,
      playerOptions: {
        // videojs options
        controlslist: "nodownload",
        preload: "auto",
        autoplay: false,
        muted: false,
        fluid: true,
        width: document.documentElement.clientWidth,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        //playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
          },
        ],
        // children:
        // {
        // controlBar:{
        //   // volumeControl: true,
        //   // timeDivider: true, //当前时间和持续时间的分隔符
        //   // durationDisplay: true, //显示持续时间
        //   // remainingTimeDisplay: false, //是否显示剩余时间功能

        // }},

        controlBar: {
          // 设置控制条组件
          /* 设置控制条里面组件的相关属性及显示与否
    'currentTimeDisplay':true,
    'timeDivider':true,
    'durationDisplay':true,
    'remainingTimeDisplay':false,
    volumePanel: {
      inline: false,
    }
    */
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "FullscreenToggle" }, // 全屏
          ],
        },

        //poster: "http://www.znpz.com.cn/site/site01410/upfile/202101/2021012261441545.jpg",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
      },

      //音频
      currentAudioName: "",
      audioList: [{}],
      activeName: "c",

      courses: {
        title: "产品名称",
      },
      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 4,
        },
      },
    };
  },
  components: {
    AudioPlayer,
    //videoPlayer,
    Comment,
    CommentScore,
    UserIcon,
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
        this.onLoad();
      }
    },
  },
  mounted() {},
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  updated() {
    this.addpreview();
  },
  methods: {
     toHome(){
      this.$router.replace({ path: "/" });
    },
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      player;
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    initData() {
      this.activeName = "a";
    },

    // 播放前做的事
    handleBeforePlay(next) {
      // 这里可以做一些事情...
      this.currentAudioName = this.audioList[
        this.$refs.audioPlayer.currentPlayIndex
      ].name;

      next(); // 开始播放
    },

    toType(item) {
      this.$router.push({
        path: "/bbs",
        query: { typeid: item.typeId },
      });
    },
    toTopic(item) {
      this.$router.push({
        path: "/bbs",
        query: { topicid: item.topicId },
      });
    },
    toProduct(item) {
      this.$router.push({
        path: "/bbs",
        query: { productId: item.productId },
      });
    },
     toUserHomePage(id) {
        this.$router.push({
          path: "/userHomepage",
          query: { para: id },
        });
    },
    goBack(){
      if(this.$route.query.back){
        this.$router.back();
      }
      else{
        this.$router.replace({ path: '/bbs'});
      }
    },

    async onLoad() {
      this.playerOptions.sources = [];
      this.audioList = [];
      let para = this.$route.query.para;
      if (!para) {
        return;
      }
     
      let aresult = await getBbsInfo(para); //({ ...this.page })

      this.courses = aresult.data.data;
      document.title=this.courses.title;
      this.CommentPage.filterData.itemId = this.courses.id;

      
      if (this.courses.videoUrl != undefined && this.courses.videoUrl != "") {
        this.playerOptions.sources.push({
          type: "video/mp4",
          src: this.courses.videoUrl,
        });
      }
      if (this.courses.audioUrl != undefined && this.courses.audioUrl != "") {
        this.audioList.push({
          name: this.courses.title,
          url: this.courses.audioUrl,
        });
      }

      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview",this.courses.id);
        });

       });
      this.$previewRefresh();
    },

  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.znaudio {
  padding-left: 25px;
  padding-right: 25px;
  /deep/ .name {
    text-align: center;
    line-height: 120px;
  }
}
.acontent {
  line-height: 25px;
  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/  p {
    padding: revert;
    margin: revert;
}
}
.znvideo {
  /deep/ .vjs-poster {
    background-color: rgba(157, 29, 34, 0.9);
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
}

.product-proptext {
  margin-left: 20px;
}
</style>