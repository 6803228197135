<template >
  <div class="commentScore">
    <!--评分-->
    <van-cell :border="false">
      <van-col span="8">
        <h2>评分</h2>
        <div
          style="
            width: 100%;
            height: 70px;
            line-height: 70px;
            font-size: 60px;
            text-align: center;
          "
        >
          {{ itemScore.count > 0 ? itemScore.score : "-" }}
        </div>
        <van-rate
          allow-half
          v-model="itemScore.score"
          readonly
          :size="15"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
      /></van-col>

      <van-col span="16">
        <div
          v-for="star in itemScore.stars"
          :key="star.type"
          style="width: 100%; height: 24px"
        >
          <div style="width: 65px; margin-right: 3px; float: left">
            <div style="float: right">
              <van-rate
                allow-half
                v-model="star.type"
                :count="star.type"
                readonly
                disabled
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
            </div>
          </div>
          <div style="float: left; margin-top: 9px; width: 60%">
            <van-progress pivot-text="" :percentage="star.percent" />
          </div>
        </div>
      </van-col>

      <van-col span="10" offset="1">
        <van-tag
          v-if="itemScore.canScore"
          round
          size="large"
          color="#9d1d22"
          @click="showScoreHandle"
          >我来评分</van-tag
        >
        <span v-if="!itemScore.canScore">已评分</span>
      </van-col>

      <van-col span="12"
        ><div style="float: right">{{ itemScore.count }}人评分</div></van-col
      >
      <van-col span="1"><div style="float: right"></div></van-col>
    </van-cell>
    <!--我来评分-->
    <van-action-sheet @closed="showScore" v-model="showScore" title="我来评分">
      <div style="height: 600px; z-index: 9990">
        <van-cell
          :border="false"
          v-for="scoredetailitme in scoreDetail"
          :key="scoredetailitme.scoreType"
        >
          <van-col span="20" offset="4">
            {{ scoredetailitme.scoreTypeName }}</van-col
          >
          <van-col span="20" offset="4">
            <van-rate
              allow-half
              v-model="scoredetailitme.score"
              :size="46"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </van-col>

          <van-col span="10" offset="7">
            <div
              style="
                width: 100%;
                height: 65px;
                line-height: 65px;
                font-size: 60px;
                text-align: center;
              "
            >
              {{ scoredetailitme.score }}
            </div>
          </van-col>
        </van-cell>
        <div
          style="
            width: 100%;
            height: 65px;

            text-align: center;
          "
        >
          <van-button type="info" @click="setMyScore">确认</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { Toast } from "vant";

import {
  GetCommentScore,
  GetCommentScoreType,
  AddCommentScore,
} from "@/api/commentLike";

export default {
  props: {
    itemId: {
      type: String,
      default: "",
    },
    itemType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      itemScore: {
        score: "5",
        stars: [
          { type: 5, percent: 0 },
          { type: 4, percent: 0 },
          { type: 3, percent: 0 },
          { type: 2, percent: 0 },
          { type: 1, percent: 0 },
        ],
        count: 0,
        canScore: true,
      },
      scoreType: [],
      scoreDetail: [],

      showScore: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const scoreTypeReulst = await GetCommentScoreType(this.itemType);

      this.scoreType = scoreTypeReulst.data.data;
      this.scoreDetail = [];
      this.scoreType.forEach((element) => {
        this.scoreDetail.push({
          itemId: this.itemId,
          itemType: this.itemType,
          scoreType: element.id,
          scoreTypeName: element.scoreTypeName,
          scorePercent: element.scorePercent,
          score: 0,
        });
      });

      const scoreReulst = await GetCommentScore({
        itemType: this.itemType,
        itemId: this.itemId,
      });
      if (scoreReulst.data.success) {
        this.itemScore = scoreReulst.data.data;
      }
    },
    showScoreHandle() {
      this.showScore = true;
    },
    async setMyScore() {
      let check = true;
      this.scoreDetail.forEach((element) => {
        if (element.score == 0) {
          check = false;
        }
      });
      if (!check) {
        Toast("评分不能为0");
        return;
      }

      //提交参数
      let scorepara = new Array();
    
      for (let index = 0; index < this.scoreDetail.length; index++) {
        const element = this.scoreDetail[index];
        const item={
          itemId: element.itemId,
          itemType: element.itemType,
          scoreType: element.scoreType,
          scorePercent: element.scorePercent,
          score: element.score,
        }
        scorepara.push(item);
      }

      console.log(scorepara);

      await AddCommentScore({paraData:scorepara});

      this.showScore = false;
      Toast("评分成功");
      this.init();
    },

    async AddScoreHandle() {
      await AddCommentScore();
    },
  },
};
</script>

<style lang="less" scoped>
</style>